import { defineStore } from 'pinia';
import { BookingSteps } from '~/layers/booking/constants/steps';
import { PaymentStatuses } from '~/layers/booking/constants/statuses';
import type { PaymentStatus } from '~/layers/booking/types/status';
import type { IStepsRealty } from '~/layers/booking/types/realty';
import dateTimeHelper from '@frontend/ui-kit/src/utils/dateTimeHelper';
import {StatusCodes} from "http-status-codes";

export const useBookingStore = defineStore('booking', () => {
    const { t } = useI18n();

    const { getRealtyData, defaultRealty } = useBookingStepsRealty();

    const paymentUrl = ref<string>('');
    const bookingId = ref<number>(0); // идентификатор заявки
    const currentStep = ref<number>(BookingSteps.PurchaseConditions); // текущий шаг приемки
    const bookingSessionEnd = ref<Date | null>(null); // время до окончания приемки
    const currentPaymentStatus = ref<PaymentStatus>(PaymentStatuses.Undefined);
    const paymentErrorMessage = ref<string | undefined>('');

    const realtyId = ref<number>(0); // идентификатор объекта
    const realtyPrice = ref<number>(0); // стоимость объекта
    const realtyOriginalPrice = ref<number>(0); // стоимость объекта без скидки
    const realtyAccordion = ref<IStepsRealty[]>([]); // аккордеон объекта недвижимости
    const stepsRealty = ref<IStepsRealty>(defaultRealty); // информация об объекте недвижимости из аккордеона
    const isRealtyLoading = ref<boolean>(true); // лоадер аккордеона ОН
    const isShowRealtyInformation = computed<boolean>(() => {
        return (
            currentStep.value !== BookingSteps.PurchaseConditions
            && currentStep.value !== BookingSteps.Payment
        );
    });

    const isMortgageLoading = ref<boolean>(false); // лоадер при клике на кнопку заявка на ипотеку

    /** Массив шагов */
    const steps = ref<number[] >([
        BookingSteps.PurchaseConditions,
        BookingSteps.Personal,
        BookingSteps.Offer,
        BookingSteps.SuccesOrFail,
    ]);

    /** Текст тултипов при наведении на полоску шага */
    const tooltips = ref<string[]>([
        t('booking.client.purchase_conditions.tooltip'),
        t('booking.client.personal_data.tooltip'),
        t('booking.client.offer.tooltip'),
        t('booking.client.payment.tooltip'),
    ]);

    /** Переход на следующий шаг */
    const goToNextStep = () => {
        if (currentStep.value < steps.value.length - 1) {
            updateRoute(currentStep.value++);
            closeRealtyAccordion();

            if (isShowRealtyInformation) {
                loadRealtyData();
            }
        }
    };

    /**
    * Пушит в адресную строку текущий шаг
    * @param step Текущий шаг
    * @param payment Необязательный параметр; true, если оплата успешная
    */
    const updateRoute = (step: number, payment?: boolean) => {
        const router = useRouter();
        const route = useRoute();
        router.push({
            name: route.name,
            params: { id: bookingId.value },
            query: { step: getStepTitle(step, payment) }
        });
    };

    /**
    * Преобразует число текущего шага в текстовый формат
    * @param step Текущий шаг
    * @param payment Необязательный параметр; true, если оплата успешная
    */
    const getStepTitle = (step: number, payment?: boolean) => {
        switch (step) {
        case BookingSteps.PurchaseConditions:
            return 'conditions';
        case BookingSteps.Personal:
            return 'personal';
        case BookingSteps.Offer:
            return 'offer';
        case BookingSteps.Payment:
            return 'payment';
        case BookingSteps.SuccesOrFail:
            return payment ? 'success' : 'fail';
        default:
            return '';
        }
    };

    /**
    * Преобразует текстовый формат значения счетчика шагов в числовой
    * @param stepName Текущий шаг
    * @returns Номер текущего шага бронирования
    */
    const getCurrentStepNumber = (stepName: string) : number => {
        switch (stepName) {
        case 'personal':
            return BookingSteps.Personal;
        case 'offer':
            return BookingSteps.Offer;
        case 'payment':
            return BookingSteps.Payment;
        case 'success':
        case 'fail':
            return BookingSteps.SuccesOrFail;
        case 'conditions':
        default:
            return BookingSteps.PurchaseConditions;
        }
    };

    /** Закрывает аккордеон объекта недвижимости */
    const closeRealtyAccordion = () => {
        realtyAccordion.value = [];
    };

    /** Получает данные об ОН в аккордеоне шагов */
    const loadRealtyData = async () => {
        const result = await getRealtyData(bookingId.value);

        if (result.isError && (result.statusCode == StatusCodes.NOT_FOUND || result.statusCode == StatusCodes.FORBIDDEN)) {
            throw createError({ fatal: true, statusCode: result.statusCode });
        }

        const responseData = result.data!
        stepsRealty.value = responseData;
        bookingSessionEnd.value = dateTimeHelper.toDate(responseData.bookingSessionEnd);
        realtyId.value = responseData.realtyId;
        realtyPrice.value = responseData.realtyPrice;
        realtyOriginalPrice.value = responseData.originalRealtyPrice;

        isRealtyLoading.value = false;
    }

    return {
        paymentUrl,
        bookingId,
        currentStep,
        goToNextStep,
        steps,
        tooltips,
        bookingSessionEnd,
        updateRoute,
        getCurrentStepNumber,
        currentPaymentStatus,
        paymentErrorMessage,
        realtyAccordion,
        closeRealtyAccordion,
        isShowRealtyInformation,
        loadRealtyData,
        realtyId,
        realtyPrice,
        realtyOriginalPrice,
        stepsRealty,
        isRealtyLoading,
        isMortgageLoading,
    };
});
